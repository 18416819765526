import { Flex, Heading, Text } from "@lutains/ui";

import TimSrc from "~/assets/landing-page/tim-stickers-hello.png";

import "./Registered.scss";

export default function Registered() {
  return (
    <Flex direction="column" gap={6} px={4}>
      <Flex
        className="Bento Bento--black Registered"
        align="center"
        gap={6}
        justify="between"
        style={{ maxWidth: "860px" }}
      >
        <Flex direction="column" gap={6}>
          <Flex direction="column" align="center" gap={3}>
            <Heading as="h1" color="white" size={8} className="text-center">
              Ton <b className="highlight">inscription</b>
              <br /> est confirmée ! ✅
            </Heading>
          </Flex>
          <Flex direction="column" gap={6}>
            <Text color="white" size={4}>
              Tim s'active pour t'offrir un <b>accueil digne de ce nom </b>!
            </Text>
            <Text color="white" size={4}>
              Alors guette ta boîte mail, un message de bienvenue devrait
              arriver tout bientôt! 😊
            </Text>
          </Flex>
          <Flex justify="center">
            <img src={TimSrc} alt="Tim" className="Registered__tim" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
