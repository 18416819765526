import { Button, Flex, Heading, Input, Tag, Text } from "@lutains/ui";
import { CheckmarkFilled } from "@carbon/icons-react";

import TimSrc from "~/assets/landing-page/tim-2.png";

import "./Registration.scss";
import { useFetcher } from "@remix-run/react";

export default function Registration({
  email,
  error,
  isLoading,
  onChange,
  onSubmit,
}: {
  email?: string;
  error?: string;
  isLoading: boolean;
  onChange: (e: string) => void;
  onSubmit: () => void;
}) {
  const fetcher = useFetcher();

  return (
    <Flex direction="column" gap={6} px={4}>
      <Flex
        className="Bento Bento--black Registration"
        direction={{
          initial: "column",
          sm: "column",
          md: "column",
          lg: "row",
        }}
        align="center"
        gap={6}
        justify="between"
        style={{ maxWidth: "860px" }}
      >
        <Flex direction="column" gap={6}>
          <Flex direction="column" align="center" gap={3}>
            <Tag color="yellow">
              <Text size={2} weight="medium">
                ⏰Lancement 1er trimestre 2025
              </Text>
            </Tag>
            <Heading as="h1" color="white" size={8} className="text-center">
              Besoin d'aide pour ta <b className="highlight">landing page</b> ?
              Tim arrive !
            </Heading>
          </Flex>
          <Flex direction="column" gap={6} align="center">
            <Text color="white" size={4}>
              Sois parmi les premiers à accéder à une{" "}
              <b>nouvelle façon de créer du copywriting</b> qui impacte, et
              bénéficie de :{" "}
            </Text>
            <Flex
              direction="column"
              justify="center"
              className="Registration__advantages"
            >
              <Flex direction="column">
                <Flex
                  className="Registration__advantage"
                  align="center"
                  style={{ flex: 1 }}
                  gap={2}
                >
                  <CheckmarkFilled fill="#2CE9D3" width={20} height={20} />
                  <Text color="white" size={3}>
                    Promos exclusives
                  </Text>
                </Flex>
                <Flex
                  className="Registration__advantage"
                  align="center"
                  style={{ flex: 1 }}
                  gap={2}
                >
                  <CheckmarkFilled fill="#2CE9D3" width={20} height={20} />
                  <Text size={3} color="white">
                    Invitation prioritaire à la bêta
                  </Text>
                </Flex>

                <Flex
                  className="Registration__advantage"
                  align="center"
                  style={{ flex: 1 }}
                  gap={2}
                >
                  <CheckmarkFilled fill="#2CE9D3" width={20} height={20} />
                  <Text size={3} color="white">
                    Accès aux coulisses de la création des Lutains
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex direction="column" align="center">
              <Flex
                className="Registration__form"
                gap={2}
                justify="center"
                direction={{
                  initial: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                }}
              >
                <fetcher.Form>
                  <Flex
                    className="Registration__form"
                    gap={2}
                    pt={3}
                    justify="center"
                    direction={{
                      initial: "column",
                      sm: "row",
                      md: "row",
                      lg: "row",
                    }}
                  >
                    <Input
                      placeholder="Entre ton adresse mail..."
                      type="email"
                      onChange={(e) => onChange(e as string)}
                      value={email}
                      className={error && "Input__input--error"}
                    />
                    <Button
                      variant="secondary"
                      onClick={onSubmit}
                      type="submit"
                      style={{
                        background: "var(--lutains-color-violet)",
                        border: "2px solid var(--lutains-color-violet) ",
                      }}
                      disabled={!email?.length}
                      loading={isLoading}
                    >
                      Je m’inscris
                    </Button>
                  </Flex>
                </fetcher.Form>
              </Flex>
              {/* Errors */}
              <Flex align="center" pt={2}>
                {error && (
                  <Tag color="red">
                    <Text size={2} weight="medium">
                      {error === "errors.already_registered"
                        ? `Il semblerait que tu sois déjà inscrit(e) !`
                        : `Oups, une erreur s’est produite.  Tim est sur le coup !`}
                    </Text>
                  </Tag>
                )}
              </Flex>
              <Flex justify="center" gap={2} align="center">
                {/* <img src={usersSrc} alt="Users" width={60} /> */}
                <Text color="white" size={2} weight="bold">
                  Rejoins + de 70 inscrits !
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex justify="center">
            <img src={TimSrc} alt="Tim" className="Registration__tim" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
