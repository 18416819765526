import { ActionFunctionArgs } from "@remix-run/cloudflare";
import type { MetaFunction } from "@remix-run/node";
import { drizzle } from "drizzle-orm/neon-http";
import { neon } from "@neondatabase/serverless";
import { Link, useFetcher } from "@remix-run/react";
import * as Sentry from "@sentry/cloudflare";
import { Flex } from "@lutains/ui";
import { eq } from "drizzle-orm";
import { Resend } from "resend";
import { useState } from "react";

import Registration from "~/components/Newsletter/Registration";
import Registered from "~/components/Newsletter/Registered";
import useRecaptcha from "~/hooks/useRecaptcha";
import Subscription from "~/components/Email/Subscription";
import { newsletter } from "~/schema/models";
import subscription from "./subscription";

import LogoSrc from "~/assets/logo-black.png";
import "./styles.css";

export const meta: MetaFunction = () => {
  return [
    {
      rel: "script",
      href: "https://www.google.com/recaptcha/api.js",
    },
  ];
};

const Email = (props: { token: string; email: string }) => (
  <Subscription {...props} />
);

export async function action({ request, context }: ActionFunctionArgs) {
  try {
    const { email, token } = (await request.json()) as {
      email: string;
      token: string;
    };

    if (!email) {
      return Response.json(
        { errors: "errors.email_required" },
        { status: 400 }
      );
    }

    const client = neon(context.cloudflare.env.CONNECTION_STRING);
    const db = drizzle(client);

    const [user] = await db
      .select()
      .from(newsletter)
      .where(eq(newsletter.email, email))
      .limit(1);

    if (user?.subscribed) {
      Sentry.captureException(new Error("already_registered"), {
        tags: { email },
      });

      return Response.json(
        {
          errors: "errors.already_registered",
        },
        { status: 400 }
      );
    }

    const secret = context.cloudflare.env.RECAPTCHA;
    const headers = new Headers();

    headers.append(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=utf-8"
    );
    const response = await fetch(
      `https://www.google.com/recaptcha/api/siteverify?response=${token}&secret=${secret}`,
      {
        method: "POST",
        headers,
      }
    );

    const recaptcha = await response.json();

    if (!recaptcha.success) {
      Sentry.captureException(new Error("invalid_token"), {
        tags: { email },
      });

      return Response.json(
        {
          error: "errors.invalid_token",
        },
        { status: 400 }
      );
    }

    if (recaptcha.score < 0.5) {
      Sentry.captureException(new Error("suspicious_user"), {
        tags: { email },
      });

      return Response.json(
        {
          error: "errors.suspicious_user",
        },
        { status: 403 }
      );
    }

    const resend = new Resend(context.cloudflare.env.RESEND);

    const id = crypto.randomUUID();
    const userToken = crypto.randomUUID();

    await db
      .insert(newsletter)
      .values({ id, email, subscribed: true, token: userToken });

    try {
      const { error } = await resend.emails.send({
        from: "Les Lutains <yoanna@lutains.com>",
        to: [email],
        subject: "Les Lutains te souhaitent la bienvenue !",
        // Intermediary step is required for Cloudflare compilation
        html: subscription({ token: userToken, email }),
        headers: {
          "List-Unsubscribe": `https://lutains.com/unsubscribe?email=${email}&token=${userToken}`,
        },
      });

      if (error) {
        throw error;
      }
    } catch (error) {
      console.log(error);

      Sentry.captureException(new Error("internal"), {
        tags: { email },
      });

      return Response.json(
        {
          errors: "errors.internal",
        },
        { status: 500 }
      );
    }

    return { success: true };
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    return Response.json({ errors: "errors.internal" }, { status: 500 });
  }
}

export default function Index() {
  const fetcher = useFetcher<{ success: true; errors?: string }>();
  const { getToken } = useRecaptcha();

  const [email, setEmail] = useState<string>();

  const onChange = (value: string) => {
    setEmail(value);
  };

  const onSubmit = async () => {
    if (!email) {
      return;
    }

    const token = await getToken();

    fetcher.submit(
      {
        email,
        token,
      },
      {
        method: "POST",
        encType: "application/json",
      }
    );
  };

  const isLoading = fetcher.state !== "idle";
  const success = fetcher.data?.success;
  const error = fetcher.data?.errors;

  return (
    <Flex direction="column" className="Home">
      <Flex justify="center" py={4}>
        <Link to="/">
          <img src={LogoSrc} alt="Logo" width={130} />
        </Link>
      </Flex>

      <Flex justify="center" pb={6}>
        {success ? (
          <Registered />
        ) : (
          <Registration
            email={email}
            error={error}
            isLoading={isLoading}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
}
